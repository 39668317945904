import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Masážní salon Vimperk" />

    <div className="container">
      <h1 className="text-center">Masážní Salon Dimension</h1>
      <h2 className="text-center pb-1">Mária Vacíková</h2>

      <p style={{lineHeight: 1.5}}>
        U&nbsp;mě Vás čeká milé a&nbsp;přátelské prostředí.<br/>
        Díky dvacetileté praxi pro&nbsp;Vás připravím profesionální masáž s&nbsp;ohledem na&nbsp;konkrétní problém.<br/>
        Zažijte masáž z&nbsp;rukou profesionálky, která ke&nbsp;svému oboru přistupuje s&nbsp;láskou a&nbsp;s&nbsp;úctou.
      </p>

      <div>
        <a className="btn-cta" href="tel:+420776009508">Volejte 776 009 508</a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
